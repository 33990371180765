
import $ from 'jquery';
import Swiper, {Autoplay, Pagination, EffectFade, Navigation} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// require('swiper/modules/effect-fade/effect-fade.scss');
// require('swiper/modules/navigation/navigation.scss');
// require('swiper/modules/pagination/pagination.scss');
// require('swiper/components/effect-fade/effect-fade.scss');
// require('swiper/components/effect-fade/effect-fade.scss');



const getRandomKey = function(){
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
};

const getUA = function(){
  
  let _ua = navigator.userAgent.toLowerCase();
  let _o = {
    ltIE6:typeof window.addEventListener == "undefined" && typeof document.documentElement.style.maxHeight == "undefined",
    ltIE7:typeof window.addEventListener == "undefined" && typeof document.querySelectorAll == "undefined",
    ltIE8:typeof window.addEventListener == "undefined" && typeof document.getElementsByClassName == "undefined",
    IE9: navigator.appVersion.toLowerCase().indexOf("msie 9.") != -1,
    IE10 : navigator.userAgent.match(/Trident\/[6]/i),
    IE11: navigator.userAgent.match(/Trident\/7\./),
    IE:document.uniqueID,
    Firefox:window.sidebar,
    Opera:window.opera,
    chrome: _ua.indexOf('chrome') > -1,
		safari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    //Webkit:!document.uniqueID && !window.opera && !window.sidebar && !window.orientation && window.localStorage,
    mobile:/android|iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()),
    iphone:/iphone|ipod/i.test(_ua),
    android:/android/.test(_ua),
    ipad:/ipad/.test(_ua),
    tablet:undefined,
    smartphone:undefined,
    touch: window.ontouchstart === null
  }
  _o.tablet = _o.ipad;
  if(!_o.tablet && _o.android){ _o.tablet = !(/mobile/.test(_ua));}				
  _o.smartphone = _o.iphone || _o.android ? true : false;
  
  let v = [];
  if (/iP(hone|od|ad)/.test(navigator.platform)) {v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);}
  let vAry = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  _o.iosV = vAry[0] ? vAry[0] : '';

  return _o;
  
}

const formatMoney = function(cents, format) {
  let money_format = "¥{{amount}}";

  if (typeof cents == 'string') { cents = cents.replace('.',''); }
  var value = '';
  var placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
  var formatString = (format || money_format);

  function defaultOption(opt, def) {
     return (typeof opt == 'undefined' ? def : opt);
  }

  function formatWithDelimiters(number, precision, thousands, decimal) {
    precision = defaultOption(precision, 2);
    thousands = defaultOption(thousands, ',');
    decimal   = defaultOption(decimal, '.');

    if (isNaN(number) || number == null) { return 0; }

    number = (number/100.0).toFixed(precision);

    var parts   = number.split('.'),
        dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands),
        cents   = parts[1] ? (decimal + parts[1]) : '';

    //return dollars + cents;
    return dollars;
  }

  switch(formatString.match(placeholderRegex)[1]) {
    case 'amount':
      value = formatWithDelimiters(cents, 2);
      break;
    case 'amount_no_decimals':
      value = formatWithDelimiters(cents, 0);
      break;
    case 'amount_with_comma_separator':
      value = formatWithDelimiters(cents, 2, '.', ',');
      break;
    case 'amount_no_decimals_with_comma_separator':
      value = formatWithDelimiters(cents, 0, '.', ',');
      break;
  }

  return formatString.replace(placeholderRegex, value);
};

const lockBG = {
	locked : false,
	scrollOffsetY : 0,
	toggle : function(){
		if(this.locked){
			this.unlock();
		} else {
			this.lock();
		}
	},
	lock : function(){
		this.scrollOffsetY = $(window).scrollTop();
		$('body').css({position: 'fixed', top: -this.scrollOffsetY, width: '100%'});	
		this.locked = true;	
	},
	unlock : function(){
		$('body').css({position: 'static', top: 'auto'});		
		window.scrollTo(0, this.scrollOffsetY);	
		this.locked = false;	
	}		
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

class modalWindow {

	isOpen = false;
  $win = $(window);
	modalwrapper;
	modalContainer;
	modalContents;	
	contents;
	optionsDefault = {url: null, resetContent: true, iscroll: true, fullscreen: false, align: 'top', marginTop: 0, speed: 1000, style:{backgroundColor: '#000'}}
	options;
	onUpdateCallback;
	spMyScroll;
  ua = {};

	constructor (options = {}) {

		const key = options.modalID ? options.modalID : 'modal' + getRandomKey();
		const html = '<div id="'+ key +'" class="modal">'+
			'<div class="modal__container">'+
				'<div class="modal__contents">'+
				'</div>'+
			'</div>'+
		'</div>';

		//alert(key)
		$('body').append(html);
		this.modalwrapper = $('#' + key);
		this.modalContainer = this.modalwrapper.find('.modal__container');
		this.modalContents = this.modalwrapper.find('.modal__contents');
		this.ua = getUA();

		//)

		if(options.content){
			this.contents = $(options.content);
			$(this.contents).appendTo(this.modalContents);
		}

	}

	setContents(data, param){
		let obj = param;
		this.options = $.extend({}, this.optionsDefault, obj);
		
		if(data)
		this.contents = data;

	}

	toggle (obj){

		let w = this.$win.width();
		let h = this.$win.height();

		let callbacks = {
			beforeOpen : ()=>{},
			beforeClose : ()=>{},
			onOpen : ()=>{},
			onClose : ()=>{}
		}

		
		callbacks = $.extend({}, callbacks, obj);
		

		let callbackbeforeOpen = callbacks.beforeOpen;
		let callbackbeforeClose = callbacks.beforeClose;
		let callbackOnOpen = callbacks.onOpen;
		let callbackOnClose = callbacks.onClose;

		if(!this.isOpen){

			let windowTop = this.$win.scrollTop();

			$('body').css({
				position: 'fixed',
				left: 0,
				top: windowTop,
				width: '100%'
			});

			// $('.close-modal-button', this.modalContainer).on('click', (e)=>{
			// 	e.preventDefault();
			// 	this.toggle(callbackOnOpen);
			// });
			
			this.$win.on('resize.modalWindow load.modalWindow', ()=>{
				this.resize();
			});
		
      if(this.options.resetContent == true){
			  this.modalContents.append(this.contents);
      }

			
			this.modalwrapper.attr('style', '');
			this.modalwrapper.css({width: w, height: h, top: 0, left: 0, opacity: 0}).addClass('modal-style-01').css(this.options.style).show();
      
			this.modalwrapper.stop().animate({
				opacity: 1
			}, this.options.speed, 'easeOutCubic');

			this.modalContainer.css({opacity: 0, top: 0, left: 0}).show();
			callbackbeforeOpen();
      this.modalContainer.scrollTop(0);

			this.modalContainer.stop().animate({
				opacity: 1
			}, this.options.speed, 'easeOutCubic', ()=>{
				
				callbackOnOpen();
        
				if(this.options.iscroll && this.ua.smartphone){
					
					// $('> *', this.modalContents).wrap('<div id="modal-iscroll-modalContainer"><div id="modal-iscroll-modalContainer-inner"></div></div>');
					// $('#modal-iscroll-modalContainer').css({height: '80vh', overflow: 'hidden'});
					// //$('#modal-iscroll-modalContainer-inner').css({position: 'absolute', left: 0, top: 0, width: '100%'});
					// $('#modal-iscroll-modalContainer').imagesLoaded(function(){
					// 	this.spMyScroll = new BScroll('#modal-iscroll-modalContainer', { mouseWheel: true, click: true });
					// 	//this.spMyScroll.refresh();
					// });

				}		

			});
		
			this.modalContainer.on('click', (e)=>{		
        if(!$(e.target).hasClass('close-modal-button') && !$(e.target).closest('.close-modal-button').length){
          if($(e.target).hasClass('modal__container') == true || $(e.target).hasClass('modal__contents') == true)
          this.toggle(callbacks);
        }
			});
			
			this.modalContainer.find('.close-modal-button').on('click', (e)=>{
				e.preventDefault();
				this.toggle(callbacks);
			});
				
		
					
			this.isOpen = true;
			this.resize();
			
		} else {
		
			callbackbeforeClose();

			this.modalwrapper.stop().fadeOut(this.options.speed, 'easeOutCubic');			
			this.modalContainer.stop().fadeOut(this.options.speed, 'easeOutCubic', ()=>{
				this.modalwrapper.removeClass('modal-style-01');

        if(this.options.resetContent == true){
				  this.modalContents.html('');
        }

				if(this.options.resetContent == true){
					this.contents = null;	
				}
				this.isOpen = false;
				this.isOpen = null;
				this.options =  null;
				this.modalwrapper.hide();
				this.modalContainer.hide();


			});			
			
			this.modalContainer.off('click');
			$('.close-modal-button').off('click');
			
			this.onUpdateCallback = null;
			
			setTimeout(function(){
				callbackOnClose();
			}, this.options.speed);		
			
			let currentTop = $('body').css('top').replace('px', '');
      

			$('body').css({
				position: 'static',
				left: 0,
				top: 0,
				width: 'auto'
			});
console.log(currentTop)
			this.$win.scrollTop(currentTop); 
			
			this.$win.off('resize.modalWindow load.modalWindow');	

		}
	}

	scrollTo(target){
		this.modalContainer.scrollTop(target);
	}

	resize(){
		
		let w = this.$win.width();
		let h = this.$win.height();
		let _self = this;

		if(this.isOpen){
			var diff = this.ua.iphone ? 100 : 0;
			this.modalwrapper.css({ width: w, height: h + diff});
			this.modalContainer.css({width: w, height: h + diff});	
			
			if(this.options.align == 'center'){

				setTimeout(function(){
					var contH = _self.contents.outerHeight();
					var contY = (h - contH) / 2;
					var contW = _self.contents.outerWidth();
					var contX = (w - contW) / 2;	
					_self.modalContents.css({marginTop: Math.max(contY, 10)});
				}, 10);

			} else {
				this.modalContents.css({marginTop: this.options.marginTop});
			}
			
      if(this.contents){
        if(this.contents.height() < w){
          this.modalContainer.css({overflowY: 'auto'});
        } else {
          this.modalContainer.css({overflowY: 'scroll'});
        }
      }
		}

	}

}


const slider = (elem, options)=>{

	Swiper.use([Autoplay]);
	Swiper.use([Pagination]);
	Swiper.use([EffectFade]);  
	Swiper.use([Navigation]);  
	
	if($(elem).find('.swiper-slide').length < 2){
		return false;
	}
	
	//$(selector).each((i,elem)=>{
  
		let swiperOptions = options ? options : {
			speed: 500,
			//loop: true, 
			//spaceBetween: 40,
			simulateTouch: false,
			pagination: {
				el: $(elem).find('.swiper-pagination').get(0),
				clickable: true,
			},
			breakpoints: {
				900: {
					shortSwipes: false,
					simulateTouch: false,
				}
			}
		};
	
		// let elemID = 'swiper-' + (i+1);
		// $(elem).attr('id', elemID);
	// var swiper = new Swiper(".swiper", {
//   slidesPerView: "auto",
//   spaceBetween: 20,
//   pagination: {
//     el: ".swiper-pagination",
//     clickable: true,
//   },
//   breakpoints: {
//     600: {
//       slidesPerView: 2,
//       spaceBetween: 30,
//     },
//     900: {
//       slidesPerView: 3,
//       spaceBetween: 40,
//     }
//   }
// });

		const thumbnails = swiperOptions.thumbnails ? swiperOptions.thumbnails : $(elem).data('swiper-thumbnails');

		const loop = $(elem).data('swiper-loop');
		const spaceBetween = $(elem).data('swiper-space');
		const effect = $(elem).data('swiper-effect')
		const autoplay = $(elem).data('swiper-auto')
		const speed = $(elem).data('swiper-speed')
		const nav_index = $(elem).data('swiper-index')
		const direction = $(elem).data('swiper-direction')
	
		if(loop) swiperOptions.loop = loop;
	
		if(spaceBetween) swiperOptions.spaceBetween = spaceBetween;
	
		if(direction) swiperOptions.direction = direction;
	
		if(autoplay){
			swiperOptions.autoplay = {
				delay: 5000,
				disableOnInteraction: true,
			}    
		}

		if(speed){
			swiperOptions.speed = speed;
		}		
	
		if(effect){
			swiperOptions.effect = effect;
			if(effect == 'fade') {
				swiperOptions.fadeEffect = {
					crossFade: true
				}   
			}
		} 
		
		if(nav_index){
			$(elem).find('.swiper-index__total').text($(elem).find('.swiper-slide').length);
		 
			swiperOptions.on = {
				init: (data)=>{
					$(elem).find('.swiper-index__current').text(data.activeIndex + 1);
				},         
				slideChange: (data)=>{
					$(elem).find('.swiper-index__current').text(data.activeIndex + 1);
				}
			}
		}

		if($(elem).find('.swiper-pagination').length){
			swiperOptions.pagination = {
				el: $(elem).find('.swiper-pagination').get(0),
				type: 'bullets',
				clickable: true
			}
		}
		
		const swiper = new Swiper($(elem).get(0), swiperOptions);
	
		if(thumbnails) {
			$(thumbnails).find('button').on('click', (e)=>{
				let index = $(e.currentTarget).index();
				console.log(index);
				swiper.slideTo(index);
			})
		}
	
		return swiper;

}


class menu {

	menuOpen = false;
  $win = $(window);
	toggleButton;
	menu;
	timer;
	optionsDefault = {toggleButton: '.toggle-nav-button', menu: '.site-nav'}
	options;

	constructor (options = {}) {
		this.options = $.extend({}, this.optionsDefault, options);
		$(this.options.toggleButton).on('click', ()=>{
			this.toggle();
		})

		// $('.toggle-search-box-button').on('click', (e)=>{
		// 	$('.search-box').toggleClass('visible');
		// 	$('.search-box input[type=search]').focus();
		// });
	
		$(window).on('click', (e)=>{
			if(!$(e.target).closest(this.options.menu).length && !$(e.target).closest(this.options.toggleButton).length){
				this.close();
			}
		});

	}



	open (){

		this.menuOpen = true;

		$('body').addClass('nav-open');
		$(this.options.menu).addClass('visible');
		$(this.options.toggleButton).addClass('opened');
		
    //lockBG.lock();

		// if(this.timer) clearTimeout(this.timer);
		// this.timer = setTimeout(
		// 	()=>{				
		// 		$(this.options.menu).addClass('visible');
		// 		$('.toggle-nav-button').addClass('opened');
		// 	}, 100
		// );	
	
	}

	close (){

		this.menuOpen = false;

		$('body').removeClass('nav-open');
		$(this.options.menu).removeClass('visible');
		$(this.options.toggleButton).removeClass('opened');
		
    //lockBG.lock();

		// if(this.timer) clearTimeout(this.timer);
		// this.timer = setTimeout(
		// 	()=>{				
		// 		$(this.options.menu).addClass('visible');
		// 		$('.toggle-nav-button').addClass('opened');
		// 	}, 100
		// );	
	
	}

	toggle(){
		if(this.menuOpen){
			this.close();
		} else {
			this.open();
		}
	}	

}

const getParam = (param)=>{

	const urlParams = new URLSearchParams(location.search);
	for (const [key, value] of urlParams) {
		if(key == param){
			return value;
		}
	}
	return null;

}

const addParam = (params)=>{

	const urlParams = new URLSearchParams(location.search);
//console.log(urlParams)

		
		let paramObj = {};
		let searchStr = '';

		if(urlParams){
			for (const [key, value] of urlParams) {
				paramObj[key] = value;
			}
		}

		for (const [key, value] of Object.entries(params)) {
			if(value){
				paramObj[key] = value;
			} else {
				delete paramObj[key];
			}
		}

		let searchStrAry = [];
		for (const [key, value] of Object.entries(paramObj)) {
			searchStrAry.push(key + '=' + value);
		}

		let searchQueryString = searchStrAry.join('&');
		

		searchStr = searchQueryString ? '?' + searchQueryString : '';

		return searchStr;


	//return null;

}

export {
  lockBG,
	menu,
	slider,
  modalWindow,
  validateEmail,
  formatMoney,
	getParam,
	addParam
}